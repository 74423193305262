
html,body{
  height: 100%;
  font-family: 'Ubuntu', sans-serif;
}
.App{
  background: linear-gradient(to bottom, rgb(94, 114, 227) 35%,rgb(241, 241, 241) 20%) ;
  overflow-x: hidden;

}

/* zone card */
.icon{
  color: rgb(231, 231, 7);
  padding: .8rem;
  border-radius: 10px;
}
/* login */
.login-bg{
  background-color: #fff;
  
}
.log{
  margin-top: 6.5%;
}
.log-title{
  color: gray;
}
.logo{
  width: 35%;
}
.inputs input{
  width: 100%;
  margin: .5rem 0;
  padding: .7rem 1rem;
  outline: none;
  border-radius: 3px;
  border: 1px solid rgb(204, 201, 201);
}
.inputs button{
  width: 100%;
  margin-top: 1rem;
  padding: .3rem 0;
  background-color:#3E51B5;
  color: white;  
  border: none;
  border-radius: 3px;
  font-size: .9rem;
}
.inputs button:hover{
  background-color:rgba(62,81,181,0.9);
}
@media(max-width:992px){
  
  .logo{
    width: 50%;
  }
  .login{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    background-image: url('./assets/login-bg2.png');
    background-size: cover;
  }
  .log{
    border: 1px solid white !important;
    padding: 1rem;
    margin: 0 .5rem;
    background: rgba(255, 255, 255, 0.2);
  }
  .log-title{
    color: white;
  }
.inputs input{
  width: 100%;
  margin: .8rem 0;
}
}