
.mynav{
    color: #fff;
  }
  
  .mynav li a {
    color: #fff;
    text-decoration: none;
    width: 100%;
    display: block;
    border-radius: 5px;
    padding: 8px 5px;
  }
  
  .mynav li a.active{
    background: rgba(255,255,255,0.2);
  }
  
  .mynav li a:hover{
    background: rgba(255,255,255,0.2);
  }
  
  .mynav li a i{
    width: 25px;
    text-align: center;
  }
  
  .notification-badge{
    background-color: rgba(255,255,255,0.7);
    float: right;
    color: #222;
    font-size: 14px;
    padding: 0px 8px;
    border-radius: 2px;
  }
  
  .links li{
    list-style: none;
  }
  /* sub side bar */
  #sub{
    width: 265px;
    height: 90vh;
    background-color: #ffffff;
    position: absolute;
    border-radius: 2%;
    border: 1px solid #f9fbfd;
    /* box-shadow: 10px 4px 20px black; */
    left:100%;
    top: 1.5%;
    z-index: 10;
    transition: .3s ease;
    transform: translateX(-700px);
  }
  @media(max-width:700px){
    #sub{
      position: absolute;
      left:0;
      top: 14%;
    }
  }
  